import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
export default class Privacy extends React.Component {
  state = {
    data: null,
  }

  componentDidMount() {
   
  }

  render() {
    return (
      <Layout>
        <div className="relative overflow-hidden">
          <div className="max-w-screen-xl mx-auto p-6 md:pt-12 sm:p-8">
            <div className="md:flex p-4 flex-col">
        <Header>
          <div>
            <p
                    style={{
                      fontWeight: 600,
                      fontSize: 24,
                    }}
                    className="mt-2 text-xs sm:text-sm md:text-md para"
                  >
                    Being.Zone
                  </p>
            <p
              style={{
                fontWeight: 600,
                fontSize: 20,
              }}
              className="mt-2 text-xs sm:text-sm md:text-md para"
            >
              Privacy Policy
            </p>
          </div>
        </Header>
              <div className="legal-container mt-4" style={{
                fontWeight: 500,
                fontSize: 15,
                color: "#000"
              }}>
            
                <p className="font-bold">
                  This Privacy Policy was last updated on March 18, 2022.
                </p>
                <p>Welcome to the website (the “Site”) of Being.Zone (“BeingZone”, “we”, “us” and/or “our”).  This Website, Mobile Application, and SERVICES are provided by 8anga Life Pvt. Ltd. This Site has been created to provide information about our company and our services, mobile applications and related services (together with the Site, the “Services”) to our Service visitors including Customers and Coaches (“you”, “your”). This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
                </p>
                <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
                </p>
                <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
                </p>
                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Being.Zone unless otherwise defined in this Privacy Policy.
                </p>
                <p>This Privacy Policy sets forth BeingZone’s policy with respect to information including personally identifiable data (“Personal Data”) and other information that is collected from visitors to the Site and Services.
                </p>

                <p className="font-bold">
                  Information We Collect:
                </p>
                <p>When you interact with us through the Services, we may collect Personal Data and other information from you, as further described below:
Personal Data That You Provide Through the Services: We collect Personal Data from you when you voluntarily provide such information, such as when you contact us with inquiries, respond to one of our surveys, register for access to the Services or use certain Services. Wherever BeingZone collects Personal Data we make an effort to provide a link to this Privacy Policy. By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Privacy Policy. 
                </p>
                <p className="font-bold">
                  Consent:
                </p>
                <p>1) User is provided to go through the Terms and Conditions and privacy policy page before registration
                </p>
                <p>2) Unsubscribe: Users can unsubscribe from the product and our services by writing to us at “support@being.zone”.
                </p>
                <p className="font-bold">
                  Our Use of Your Personal Data and Other Information:
                </p>
                <p>BeingZone uses the Personal Data you provide in a manner that is consistent with this Privacy Policy. If you provide Personal Data for a certain reason, we may use the Personal Data in connection with the reason for which it was provided. For instance, if you contact us by email, we will use the Personal Data you provide to answer your question or resolve your problem.
                </p>
                <p>Also, if you provide Personal Data in order to obtain access to the Services, we will use your Personal Data to provide you with access to such services and to monitor your use of such services. BeingZone and its subsidiaries and affiliates (the “Related Companies”) may also use your Personal Data and other personally non-identifiable information collected through the Services to help us improve the content and functionality of the Services, to better understand our users and to improve the Services. BeingZone and its affiliates may use this information to contact you in the future to tell you about services we believe will be of interest to you. If we do so, each marketing communication we send you will contain instructions permitting you to &quot;opt-out&quot; of receiving future marketing communications.
In addition, if at any time you wish not to receive any future marketing communications or you wish to have your name deleted from our mailing lists, please contact us at support@being.zone
                </p>

                <p className="font-bold">
                  Disclosure of Your Personal Data and Other Information:
                </p>
                <p>BeingZone is not in the business of selling your information. We consider this information to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, as set forth below:
                </p>
                <p><b>Business Transfers:</b> As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets.
                </p>
                <p><b>Related Companies:</b> We may also share your Personal Data with our Related Companies for purposes consistent with this Privacy Policy.
                </p>
                <p><b>Agents, Consultants and Related Third Parties:</b> BeingZone, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases and processing payments. When we employ another entity to perform a function of this nature, we only provide them with the information that they need to perform their specific function.
                </p>
                <p><b>Legal Requirements:</b> BeingZone may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of BeingZone, (iii) act in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) protect against legal liability.
                </p>




                <p className="font-bold">
                  Exclusions:
                </p>
                <p>This Privacy Policy does not apply to any Personal Data collected by BeingZone other than Personal Data collected through the Services. This Privacy Policy shall not apply to any unsolicited information you provide to BeingZone through the Services or through any other means. This includes, but is not limited to, information posted to any public areas of the Services, such as forums, any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, “Unsolicited Information”). All Unsolicited Information shall be deemed to be non-confidential and BeingZone shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.
                </p>

                <p className="font-bold">
                  Security
                </p>
                <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
                </p>

                <p className="font-bold">
                  Links to Other Sites
                </p>
                <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                </p>

                <p className="font-bold">
                  Children’s Privacy
                </p>
                <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
                </p>

                <p className="font-bold">
                  Changes to This Privacy Policy
                </p>
                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
                </p>

                <p className="font-bold">
                  Contact Us
                </p>
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at contact@being.zone, #14, 2nd Floor Srinidhi Building, J.P Nagar 8th Phase, Bengaluru, Karnataka 560076, India.
                </p>




              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
